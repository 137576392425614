<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import InvoiceDataService from "@/services/InvoiceDataService";

export default {
  name: 'InvoiceList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'invoices' },
    vueSlug: { default: 'invoice' }
  },
  data() {
    return {
      dataService: InvoiceDataService,
      fieldConfig: InvoiceDataService.getGridConfig(),
    }
  }
}
</script>
