<template>
  <div>
    <InvoiceList />
  </div>
</template>

<script>
import InvoiceList from "@/components/InvoiceList";

export default {
  name: "InvoicesView",
  components: { InvoiceList },
}
</script>
